<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/dictionaries/${$route.params.namespace}/create`">
        <CButton
          color="success"
        >
          Создать запись в справочнике
        </CButton>
      </router-link>
      <br>
      <br>
      <CDataTable
        v-if="$route.params.namespace === 'dictionary-coverings'"
        :items="dictionaryCoverings"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'введите название...'}"
      >
        <template #name="{ item }">
          <td class="td">
            <router-link :to="`/dictionaries/${$route.params.namespace}/update/${item.id}`">
              {{ item.name }}
            </router-link>
          </td>
        </template>
      </CDataTable>
      <CDataTable
        v-if="$route.params.namespace === 'dictionary-mount-type'"
        :items="dictionaryMountTypes"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'введите название...'}"
      >
        <template #name="{ item }">
          <td class="td">
            <router-link :to="`/dictionaries/${$route.params.namespace}/update/${item.id}`">
              {{ item.name }}
            </router-link>
          </td>
        </template>
      </CDataTable>
      <CDataTable
        v-if="$route.params.namespace === 'dictionary-sputtering'"
        :items="dictionarySputterings"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'введите название...'}"
      >
        <template #name="{ item }">
          <td class="td">
            <router-link :to="`/dictionaries/${$route.params.namespace}/update/${item.id}`">
              {{ item.name }}
            </router-link>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>

.test {
    background-color: red;
}

.c-card-body {
    background-color: #fefefe ;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
    text-align: right;
}
</style>

<script>
import { mapState, mapActions } from "vuex";

const fields = [
  {
    key: "name",
    label: "Запись в справочнике",
  }
];

export default {
  name: "Dictionary",
  data() {
    return {
      fields
    };
  },
  computed: {
    ...mapState({
      dictionaryCoverings: (state) => state.dictionaryCoverings.all.items,
      dictionaryMountType: (state) => state.dictionaryMountType.all.items,
      dictionarySputtering: (state) => state.dictionarySputtering.all.items
    }),
  },
  created() {
    if (this.$route.params.namespace === "dictionary-coverings") {
      this["dictionaryCoverings/getAll"]();
    } else if (this.$route.params.namespace === "dictionary-mount-types") {
      this["dictionaryMountType/getAll"]();
    } else if (this.$route.params.namespace === "dictionary-sputterings") {
      this["dictionarySputterings/getAll"]();
    }
  },
  methods: {
    ...mapActions([
      "dictionaryCoverings/getAll",
      "dictionaryMountTypes/getAll",
      "dictionarySputterings/getAll"
    ])
  },
};
</script>
